// @flow

import type { ComponentType } from 'react'
import * as React from 'react'
import styled from '@emotion/styled'
import {
  Button,
  Card,
  Hero,
  hooks,
  text,
  Table,
  Accordion,
  Section,
  Icon,
} from '@toggl/ui'
import { colors, fonts, media, themes } from '@toggl/style'
import IndexLayout from '../layouts'
import LayoutGlobal from '../components/LayoutGlobal'
import glassdoor from 'images/uploads/glassdoor.png'
import videoPlaceholder from 'images/uploads/toggl-video-placeholder.jpg'
import statisticImg from 'images/uploads/jobs-statistics.png'
import { ThemeProvider } from 'emotion-theming'
import { Link } from 'gatsby'
import ogImage from 'images/uploads/work-at-toggl.jpg'
import type { Job } from '../types'

// $FlowFixMe
import yamlData from '../../data/global/jobs.yml'

const sections = [
  {
    title: 'What is the Toggl hiring process like? ',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum facilisis auctor ante vel semper. Phasellus tellus neque, lobortis eu risus ac, egestas vehicula ante. Vivamus facilisis tellus in lacus ultricies viverra. Nam non luctus sem.',
  },
  {
    title: 'What is the Toggl hiring process like? ',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum facilisis auctor ante vel semper. Phasellus tellus neque, lobortis eu risus ac, egestas vehicula ante. Vivamus facilisis tellus in lacus ultricies viverra. Nam non luctus sem.',
  },
  {
    title: 'What is the Toggl hiring process like? ',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum facilisis auctor ante vel semper. Phasellus tellus neque, lobortis eu risus ac, egestas vehicula ante. Vivamus facilisis tellus in lacus ultricies viverra. Nam non luctus sem.',
  },
]

const getTableData = (job) => ({
  title: job.title,
  position: job.position,
  description: job.description,
  team: job.team,
  slug: job.slug,
})

const JobsPage = () => {
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  const jobs: Array<Job> = yamlData.jobs
  const TableRef = React.useRef(null)

  const HeroComponent = ({ children, TableRef }) => {
    return (
      <Hero.Wrapper solidColor={colors.darkPurple} skewed={true}>
        {children}

        <HeroContentWrapper>
          <HeroTitle centered>
            Your new commute:
            <br /> <Rotalic>zero</Rotalic> minutes.
          </HeroTitle>
          <ButtonWrapper>
            <Button.Primary
              onClick={() => {
                window.scrollTo({
                  top: TableRef.current?.offsetTop || 0,
                  behavior: 'smooth',
                })
              }}
            >
              See job openings
            </Button.Primary>
          </ButtonWrapper>
        </HeroContentWrapper>
      </Hero.Wrapper>
    )
  }

  return (
    <IndexLayout
      title={'Work with Toggl’s Global Remote Team'}
      description={
        'Our amazing team works from 40+ countries around the globe. We hire globally, you work locally. Check out our open positions!'
      }
      ogImage={ogImage}
    >
      <LayoutGlobal Hero={HeroComponent}>
        <Diagonal>
          <Section.Skewed heightBackground={isDesktop ? '60%' : '80%'}>
            <TextSection>
              <H1>
                Working att <Rotalic>Toggl</Rotalic>
              </H1>
              <Text>
                Our amazing team works from 40+ countries around the globe. We
                hire globally, you work locally - in the middle of New York
                City, a beach outside of São Paulo, or a quiet village near
                Florence, the choice is yours.
              </Text>
              <GlassdoorWrapper>
                <h3>Hear directly from the Toggl Track team on Glassdoor</h3>
                <a
                  target="_blank"
                  to={
                    'https://www.glassdoor.com/Overview/Working-at-Toggl-EI_IE734060.11,16.htm'
                  }
                >
                  <Icon.Track />
                </a>
                <a
                  target="_blank"
                  to={
                    'https://www.glassdoor.com/Overview/Working-at-Toggl-EI_IE734060.11,16.htm'
                  }
                >
                  <Logo src={glassdoor} />
                </a>
              </GlassdoorWrapper>
            </TextSection>

            <ModalVideoStyled placeholder={videoPlaceholder} skewed>
              <iframe
                src={'https://www.youtube.com/watch?v=vU3QC0NNoDo'}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              />
            </ModalVideoStyled>
          </Section.Skewed>
        </Diagonal>
        <TableWrapper ref={TableRef}>
          <Table.ThreeCol
            title="Software development"
            jobs={jobs
              .filter((vacancy) => vacancy.category === 'software-development')
              .map(getTableData)}
            Link={Link}
          />
        </TableWrapper>
        <TableWrapper>
          <Table.ThreeCol
            title="Marketing And Sales"
            jobs={jobs
              .filter((vacancy) => vacancy.category === 'marketing-and-sales')
              .map(getTableData)}
            Link={Link}
          />
        </TableWrapper>

        <Section.Block background={colors.fadedPurple}>
          <Accordion title="FAQ's" sections={sections} active={0} />
        </Section.Block>
        <Section.Block background={colors.fadedPurple}>
          <Card.Large>
            <CaseStudyContent>
              <CaseStudyContentLeft>
                <CaseStudyContentLeftH2>
                  “When we pitch new businesses, we bring our Toggl reports and
                  demonstrate the level of visibility we can provide”
                </CaseStudyContentLeftH2>

                <CaseStudyContentLeftP1>
                  See how tech consultancy Fullstack Labs used Toggl to
                  outcompete their rivals.
                  <Button.Secondary>Read case study</Button.Secondary>
                </CaseStudyContentLeftP1>
              </CaseStudyContentLeft>

              <CaseStudyContentRight>
                <ThemeProvider theme={themes.track}>
                  <Section.ModalVideo placeholder={statisticImg} skewed={false}>
                    <iframe
                      src={'https://www.youtube.com/watch?v=vU3QC0NNoDo'}
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                    />
                  </Section.ModalVideo>

                  <CaseStudyContentRightH3>
                    Curious how Toggl Track can help your business?
                  </CaseStudyContentRightH3>
                  <Button.Primary>Try now</Button.Primary>
                </ThemeProvider>
              </CaseStudyContentRight>
            </CaseStudyContent>
          </Card.Large>
        </Section.Block>
      </LayoutGlobal>
    </IndexLayout>
  )
}

const GlassdoorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    color: ${colors.darkPurple};
  }

  svg {
    width: 160px;
    margin: 20px 0;
  }
`

const CaseStudyContentRightH3 = styled.h3`
  ${text.heading4};
  color: ${colors.darkPurple};
  width: 284px;
  margin: 27px 0;

  ${media.mq[1]} {
    margin-top: 63px;
    margin-bottom: 25px;
  }
`

const CaseStudyContentRight = styled.div`
  padding: 30px 5px 64px 18px;
  background-color: ${colors.beige};
  justify-content: center;
  text-align: center;

  button {
    width: 220px;
    justify-content: center;
    margin: 0 auto;
  }

  ${media.mq[1]} {
    padding: 90px 35px 68px 60px;
    display: flex;
    flex-direction: column;
    width: 485px;
    height: 600px;
    text-align: left;

    button {
      width: 224px;
      justify-content: center;
      margin: 0;
    }
  }
`

const CaseStudyContentLeftP1 = styled.p`
  ${text.paragraph3};
  line-height: 1.5;
  color: ${() => colors.beige};
  width: 256px;

  button {
    margin-top: 19px;
    color: ${() => colors.beige};
  }

  ${media.mq[1]} {
    ${text.paragraph2};
    width: 441px;

    button {
      margin-top: 39px;
    }
  }
`

const CaseStudyContentLeftH2 = styled.h2`
  ${text.heading4};
  color: ${colors.beige};
  padding: 18px 0;
  width: 263px;

  ${media.mq[1]} {
    ${text.heading3};
    padding: 0;
    width: 466px;
    margin-bottom: 144px;
  }
`

const CaseStudyContentLeft = styled.div`
  padding: 50px 26px 50px 30px;

  ${media.mq[1]} {
    width: 710px;
    padding: 90px 121px;
  }
`

const CaseStudyContent = styled.div`
  background-color: ${colors.darkPurple};

  ${media.mq[1]} {
    display: flex;
    height: 600px;
  }
`

const TableWrapper = styled.div`
  background: ${colors.fadedPurple};
  display: flex;
  align-items: center;
  padding-top: 60px;
  width: 100%;
  justify-content: center;
`

const ModalVideoStyled = styled(Section.ModalVideo)`
  ${media.mq[1]} {
    transform: translateY(-120px);
  }
`

const HeroTitle = styled(Hero.Title)`
  width: 205px;
  margin: 26px auto;
  ${media.mq[1]} {
    width: auto;
  }
`

const HeroContentWrapper = styled(Hero.ContentWrapper)`
  padding-top: 60px;
  padding-bottom: 60px;
  ${media.mq[1]} {
    padding-top: 115px;
    padding-bottom: 0;
  }
`
const Diagonal = styled.div`
  background: ${colors.darkPurple};
`
const Text: ComponentType<any> = styled.div`
  ${text.paragraph3}
  line-height: 1.5;
  margin-bottom: 20px;
  color: ${colors.darkPurple};
  ${media.mq[1]} {
    margin-bottom: 20px;
  }
`

const H1: ComponentType<any> = styled.h1`
  ${text.heading1}
  font-size: 3rem;
  line-height: 1.125;
  margin-bottom: 17px;
  color: ${colors.fadedPurple};

  ${media.mq[1]} {
    ${text.heading1};
    color: ${colors.fadedPurple};
  }
`

const TextSection: ComponentType<any> = styled.div`
  margin: 0 auto 50px auto;
  width: 70%;

  ${media.mq[1]} {
    height: 800px;
    width: 372px;
    margin-right: 200px;
    ${H1} {
      margin-bottom: 40px;
    }
  }
`

const Logo: ComponentType<any> = styled.img`
  height: 43px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${media.mq[1]} {
    > button {
      margin-top: 40px;
    }
  }
`

const Rotalic = styled.span`
  font-family: ${fonts.gtHaptikMediumRotalic};
`

export default JobsPage
